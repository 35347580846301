<template>
  <div>
    <div class="tRowTopMain" :class="{tRowTopMainnonMobile : isMobile(true) }">
        <MiniMenu 
          :config="config"
          :seasonCode="seasonCode"
          :tour="tour"
        />
    </div>
    <b-container>
      <div class="ad">
        <div class="ad" style="text-align:center;">
          <script
            async
            src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
            type="application/javascript"
          ></script>
          <script type="application/javascript">
            window.googletag = window.googletag || { cmd: [] };
            googletag.cmd.push(function() {
              billboardsizes = googletag
                .sizeMapping()
                .addSize([980, 0], [728, 90])
                <!-- .addSize([0, 0], [320, 50]) -->
                .build();
              googletag
                .defineSlot(
                  "/2835490/New_PGA_Top_Banner_Home",
                  [
                    [970, 250],
                    [728, 90],
                    <!-- [320, 50] -->
                  ],
                  "div-gpt-ad-1568947342181-0"
                )
                .defineSizeMapping(billboardsizes)
                .addService(googletag.pubads());
              googletag.pubads().enableSingleRequest();
              googletag.enableServices();
            });
          </script>

          <!-- /2835490/New_PGA_Top_Banner_Home -->
          <div id="div-gpt-ad-1568947342181-0">
            <script type="application/javascript">
              googletag.cmd.push(function() {
                googletag.display("div-gpt-ad-1568947342181-0");
              });
            </script>
          </div>
        </div>
      </div>
    </b-container>
    <div class="titleLineAbove" :style="{'background-color' : primaryColor}"></div>
    <div class="titleLine">
      <b-container>
        <div class="titleBackGround">
          <h4 class="title">
            Schedules
          </h4>
        </div>
      </b-container>
    </div>
    <b-container>
      <template>
        <div class="sections mobSection showMobTours">
          <b-row>
            <b-col cols="4" class="mobButton schedTour-btn">
              <div class="mobbox shedBox">
                <b-button
                  class="schImgmob aus"
                  title="aus"
                  :href="config.VUE_APP_LIVE_URL + 'pga-tour-aus'"
                  target="_top"
                ></b-button>
              </div>
            </b-col>
            <b-col cols="4" class="mobButton schedTour-btn">
              <div class="mobbox shedBox">
                <b-button
                  class="schImgmob wpga"
                  title="wpga"
                  :href="config.VUE_APP_LIVE_URL + 'wpga-tour-aus'"
                  target="_top"
                ></b-button>
              </div>
            </b-col>
            <b-col cols="4" class="mobButton schedTour-btn">
              <div class="mobbox shedBox">
                <b-button
                  class="schImgmob snr"
                  title="snr"
                  :href="config.VUE_APP_LIVE_URL + 'pga-legends-tour'"
                  target="_top"
                ></b-button>
              </div>
            </b-col>
            <b-col cols="4" class="mobButton schedTour-btn">
              <div class="mobbox shedBox">
                <b-button
                  class="schImgmob all"
                  title="all"
                  :href="config.VUE_APP_LIVE_URL + 'pga-pro-am-series'"
                  target="_top"
                ></b-button>
              </div>
            </b-col>
            <b-col cols="4" class="mobButton schedTour-btn">
              <div class="mobbox shedBox">
                <b-button
                  class="schImgmob trn"
                  title="trn"
                  :href="config.VUE_APP_LIVE_URL + 'pga-mpp-tournaments'"
                  target="_top"
                ></b-button>
              </div>
            </b-col>
            <b-col cols="4" class="mobButton schedTour-btn">
              <div class="mobbox shedBox">
                <b-button
                  class="schImgmob ppc"
                  title="ppc"
                  :href="config.VUE_APP_LIVE_URL + 'pga-professional-champs'"
                  target="_top"
                ></b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </template>
      <template>
        <div class="sections showDeskTours">
          <b-row>
            <b-col lg="2" md="6" sm="6" class="mobButton schedTour-btn">
              <div class="box shedBox">
                <b-button
                  class="schImg aus"
                  title="aus"
                  :href="config.VUE_APP_LIVE_URL + 'pga-tour-aus'"
                  target="_top"
                ></b-button>
              </div>
            </b-col>
            <b-col lg="2" md="6" sm="6" class="mobButton schedTour-btn">
              <div class="box shedBox">
                <b-button
                  class="schImg wpga"
                  title="wpga"
                  :href="config.VUE_APP_LIVE_URL + 'wpga-tour-aus'"
                  target="_top"
                ></b-button>
              </div>
            </b-col>
            <b-col lg="2" md="6" sm="6" class="mobButton schedTour-btn">
              <div class="box shedBox">
                <b-button
                  class="schImg snr"
                  title="snr"
                  :href="config.VUE_APP_LIVE_URL + 'pga-legends-tour'"
                  target="_top"
                ></b-button>
              </div>
            </b-col>
            <b-col lg="2" md="6" sm="6" class="mobButton schedTour-btn">
              <div class="box shedBox">
                <b-button
                  class="schImg all"
                  title="all"
                  :href="config.VUE_APP_LIVE_URL + 'pga-pro-am-series'"
                  target="_top"
                ></b-button>
              </div>
            </b-col>
            <b-col lg="2" md="6" sm="6" class="mobButton schedTour-btn">
              <div class="box shedBox">
                <b-button
                  class="schImg trn"
                  title="trn"
                  :href="config.VUE_APP_LIVE_URL + 'pga-mpp-tournaments'"
                  target="_top"
                ></b-button>
              </div>
            </b-col>
            <b-col lg="2" md="6" sm="6" class="mobButton schedTour-btn">
              <div class="box shedBox">
                <b-button
                  class="schImg ppc"
                  title="ppc"
                  :href="config.VUE_APP_LIVE_URL + 'pga-professional-champs'"
                  target="_top"
                ></b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-container>
      <div class="sections">
        <div class="titleLineAbove" :style="{'background-color' : primaryColor}"></div>
        <div class="titleLine">
          <b-container>
            <div class="titleBackGround">
              <h4 class="title">
                <template v-if="config.VUE_APP_NAME == 'PGA Tour'">PGATA</template>
                <template v-else>{{ config.VUE_APP_NAME }}</template>
                 Upcoming Tournaments
              </h4>
            </div>
          </b-container>
        </div>
        <b-container>
          <UpComingTours
            :futureTour="futureTours()"
            :futureTourtwo="futureTourstwo()"
            :futureTourthree="futureToursthree()"
            :tour="tour"
            :config="config"
            :seasonCode="seasonCode"
          />
        </b-container>
      </div>
    <b-container>
      <template>
        <div class="groupDropdown showMobGroup">
          <b-row>
            <b-col cols="12">
              <p class="filterTitle" :style="{'color': Primary_color}">Filter by Year</p>
              <b-form-select v-model="year" class="textPos" v-on:change="changeYear(year)">
                <b-form-select-option 
                  class="opt-arrow"
                  v-for="(value,
                  index) in data.seasons.seasons_entry.slice().reverse()"
                  :key="index"
                  :value="value.code"
                >{{value.desc}}</b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col cols="12">
              <p class="filterTitle" :style="{'color': Primary_color}">Filter by State / Country</p>
              <b-form-select v-model="pickedState" class="textPos" v-on:change="changeState(pickedState)">
                <b-form-select-option 
                  class="opt-arrow"
                  v-for="(value,
                  index) in states.slice().reverse()"
                  :key="index"
                  :value="value.state"
                >{{value.state}}</b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col cols="12">
              <p class="filterTitle" :style="{'color': Primary_color}">Filter by Month</p>
              <b-form-select v-model="selected" :options="options"></b-form-select>
            </b-col>
            <b-col cols="12">
              <p class="filterTitle" :style="{'color': Primary_color}">Find a Tournament</p>
              <input
                class="Findatourn"
                name="gsearch"
                placeholder="Find a Tournament...."
                id="filter-input"
                v-model="search"
                type="text"
              />
            </b-col>
          </b-row>
        </div>
      </template>
      <template>
      <div class="groupDropdown ShowIpadGroup">
        <b-row>
          <b-col cols="6" class="execPad">
            <p class="filterTitle" :style="{'color': Primary_color}">Filter by Year</p>
            <b-form-select v-model="year" class="textPos" v-on:change="changeYear(year)">
              <b-form-select-option 
                class="opt-arrow"
                v-for="(value,
                index) in data.seasons.seasons_entry.slice().reverse()"
                :key="index"
                :value="value.code"
              >{{value.desc}}</b-form-select-option>
            </b-form-select>
          </b-col>
          <b-col cols="6" class="execPad">
            <p class="filterTitle" :style="{'color': Primary_color}">Filter by State / Country</p>
            <b-form-select v-model="pickedState" class="textPos" v-on:change="changeState(pickedState)">
              <b-form-select-option 
                class="opt-arrow"
                v-for="(value,
                index) in states.slice().reverse()"
                :key="index"
                :value="value.state"
              >{{value.state}}</b-form-select-option>
            </b-form-select>
          </b-col>
          <b-col cols="6" class="">
              <p class="filterTitle" :style="{'color': Primary_color}">Filter by Month</p>
              <b-form-select v-model="selected" :options="options"></b-form-select>
          </b-col>
          <b-col cols="6" class="">
            <p class="filterTitle" :style="{'color': Primary_color}">Find a Tournament</p>
            <input
              class="Findatourn"
              name="gsearch"
              placeholder="Find a Tournament...."
              id="filter-input"
              v-model="search"
              type="text"
            />
          </b-col>
        </b-row>
      </div>
      </template>
      <template>
        <div class="groupDropdown showDeskGroup">
          <b-row>
            <b-col>
              <p class="filterTitle" :style="{'color': Primary_color}">Filter by Year</p>
              <b-form-select v-model="year" class="textPos" v-on:change="changeYear(year)">
                <b-form-select-option 
                  class="opt-arrow"
                  v-for="(value,
                  index) in data.seasons.seasons_entry.slice().reverse()"
                  :key="index"
                  :value="value.code"
                >{{value.desc}}</b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col>
              <p class="filterTitle" :style="{'color': Primary_color}">Filter by State / Country</p>
              <b-form-select v-model="pickedState" class="textPos" v-on:change="changeState(pickedState)">
                <b-form-select-option 
                  class="opt-arrow"
                  v-for="(value,
                  index) in states.slice().reverse()"
                  :key="index"
                  :value="value.state"
                >{{value.state}}</b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col>
              <p class="filterTitle" :style="{'color': Primary_color}">Filter by Month</p>
              <b-form-select v-model="selected" :options="options"></b-form-select>
              <!-- <b-form-select v-model="pickedMonth" class="textPos" v-on:change="changeMonth(pickedMonth)">
                <b-form-select-option 
                  class="opt-arrow"
                  v-for="(value,
                  index) in month.slice().reverse()"
                  :key="index"
                  :value="value.month"
                >{{value.month}}</b-form-select-option>
              </b-form-select> -->
            </b-col>
            <b-col>
              <p class="filterTitle" :style="{'color': Primary_color}">Find a Tournament</p>
              <input
                class="Findatourn"
                name="gsearch"
                placeholder="Find a Tournament...."
                id="filter-input"
                v-model="filter"
                type="search"
              />
            </b-col>
          </b-row>
        </div>
      </template>
      <!-- <div class="mt-3">Selected: <strong>{{ selected }}</strong></div> -->
      <div id="schedHeight">
      <ScheduleTable
        :fields="fields"
        :id="id"
        :seasonCode="seasonCode"
        :currentCode="currentCode"
        :data="filterMonth"
        :tour="tour"
        :develop="develop"
        :filter="filter"
        :config="config"
        :lastCodeCalled='scrollVaule'
        :search='search'
        :selected="selected"
        :year="year"
      />
      </div>
    <b-container>
      <div class="ad">
        <div class="ad" style="text-align:center;">
          <script
            async
            src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
            type="application/javascript"
          ></script>
          <script type="application/javascript">
            window.googletag = window.googletag || { cmd: [] };
            googletag.cmd.push(function() {
              billboardsizes = googletag
                .sizeMapping()
                .addSize([980, 0], [728, 90])
                .addSize([0, 0], [320, 50])
                .build();
              googletag
                .defineSlot(
                  "/2835490/New_PGA_Top_Banner_Home",
                  [
                    [970, 250],
                    [320, 50],
                    [728, 90],
                  ],
                  "div-gpt-ad-1632398720344-0"
                )
                .defineSizeMapping(billboardsizes)
                .addService(googletag.pubads());
              googletag.pubads().enableSingleRequest();
              googletag.enableServices();
            });
          </script>

          <!-- /2835490/New_PGA_Top_Banner_Home -->
          <div id="div-gpt-ad-1632398720344-0">
            <script type="application/javascript">
              googletag.cmd.push(function() {
                googletag.display("div-gpt-ad-1632398720344-0");
              });
            </script>
          </div>
        </div>
      </div>
    </b-container>
    </b-container>
  </div>
</template>

<script>
import MiniMenu from "@/components/miniMenu.vue";
import ScheduleTable from "@/components/scheduleTable.vue";
import UpComingTours from "@/components/upComingTours.vue";
import axios from "axios";
export default {
  name: "schedule",
  props: ["develop", "config"],
  components: {
    ScheduleTable,
    UpComingTours,
    MiniMenu,
  },
  data() {
    return {
      name: process.env.VUE_APP_NAME,
      primaryColor: process.env.VUE_APP_PRIMARY_COLOUR,
      Primary_color: process.env.VUE_APP_PRIMARY_COLOUR,
      seasonCode:[],
      currentCode:[],
      id: [],
      year: [],
      tm_params: [],
      data: [],
      scheduleList: [],
      scrollVaule: [],
      filter: [],
      filterOn: [],
      tour: process.env.VUE_APP_TOUR,
      search: '',
      pickedState: "ALL",
      pickedMonth: "ALL",
      pickedNum: "0",
      states: [
        { state: "New Zealand" },
        { state: "NSW" },
        { state: "NT" },
        { state: "QLD" },
        { state: "VIC" },
        { state: "WA" },
        { state: "ACT" },
        { state: "TAS" },
        { state: "SA" },
        { state: "Other" },
        { state: "ALL" },
      ],
      selected: 'ALL',
      options: [
        { value: "ALL", text: "ALL" },
        { value: "January", text: "January" },
        { value: "Febuary", text: "Febuary" },
        { value: "March", text: "March" },
        { value: "April", text: "April" },
        { value: "May", text: "May" },
        { value: "June", text: "June" },
        { value: "July", text: "July" },
        { value: "August", text: "August" },
        { value: "September", text: "September" },
        { value: "October", text: "October" },
        { value: "November", text: "November" },
        { value: "December", text: "December" },
      ],
      fields: [
        { key: "sponsor_logo", label: "", class: "tableTh TableLogo" },
        { key: "dates", label: "Dates", class: "tableTh TableDates" },
        { key: "full_name", label: "Tournament", class: "tableTh tableTourn" },
        { key: "winner_name", label: "Defending Champion", class: "tableTh TableDefend" },
        { key: "total_prize_fund", label: "Prize Money", class: "tableTh" },
      ],
    };
  },
  computed: {
    filterState: function() {
      var state = this.pickedState;
      if (state === "ALL") return this.data.tournaments.tournaments_entry;
      else
        return this.data.tournaments.tournaments_entry.filter(
          (tournaments_entry) =>
            !tournaments_entry.course_country.indexOf(state)
        );
    },
    filterMonth: function() {
      var pickedNum = this.pickedNum;
      if (pickedNum === "0") return this.filterState;
      else
        return this.filterState.filter(
          (tournaments_entry) =>
            !tournaments_entry.start_date.substr(3, 4).indexOf(pickedNum)
        );
    },
    // filterMonth: function() {
    //   var pickedNum = this.pickedNum;
    //   if (pickedNum === "0") return this.data.tournaments.tournaments_entry;
    //   else
    //     return this.data.tournaments.tournaments_entry.filter(
    //       (tournaments_entry) =>
    //         !tournaments_entry.start_date.substr(3, 4).indexOf(pickedNum)
    //     );
    // }, 
  },
  methods: {
    isMobile() {
      if (screen.width <= 500) {
        return true;
      } else {
        return false;
      }
    },
    isTablet() {
      if (screen.width <= 769) {
        return true;
      } else {
        return false;
      }
    },

    futureTours() {
      var data = this.data.tournaments.tournaments_entry;
      return data
        .filter((upcomingTours) => upcomingTours.days_away.indexOf("-"))
        .slice(0, 1);
    },
    futureTourstwo() {
      var data = this.data.tournaments.tournaments_entry;
      return data
        .filter((upcomingTours) => upcomingTours.days_away.indexOf("-"))
        .slice(1, 2);
    },
    futureToursthree() {
      var data = this.data.tournaments.tournaments_entry;
      return data
        .filter((upcomingTours) => upcomingTours.days_away.indexOf("-"))
        .slice(2, 3);
    },
    changeState: function(event) {
      document.getElementById('schedHeight').style.height = '1500px'
      var pickedState = event.target.getAttribute("title");
      return (
        (this.pickedState = pickedState),
        sessionStorage.setItem("pickedState", pickedState)
      );
    },
    // changeMonth: function(event) {
    //   var pickedMonth = event.target.getAttribute("title");
    //   var pickedNum = event.target.getAttribute("num");
    //   return (
    //     (this.pickedMonth = pickedMonth),
    //     (this.pickedNum = pickedNum),
    //     sessionStorage.setItem("pickedNum", pickedNum),
    //     sessionStorage.setItem("pickedMonth", pickedMonth)
    //   );
    // },
    // changeYear: function(event) {
    //   var id = event.target.getAttribute("title");
    //   return (
    //     (this.id = id),
    //     sessionStorage.setItem("id", id),
    //     axios
    //       .get(
    //         process.env.VUE_APP_TIC_BASE +
    //           this.tour +
    //           "/" +
    //           this.id +
    //           "/tmticx?field=Y&fields=tournaments.tournaments_entry.code&fields=tournaments.tournaments_entry.short_name&fields=tournaments.tournaments_entry.days_away&fields=tournaments.tournaments_entry.full_name&fields=tournaments.tournaments_entry.sponsor_logo&fields=tournaments.tournaments_entry.dates&fields=tournaments.tournaments_entry.previous_winners&fields=tournaments.tournaments_entry.winner_name&fields=tournaments.tournaments_entry.winner_ref&fields=tournaments.tournaments_entry.course&fields=tournaments.tournaments_entry.total_prize_fund&fields=tournaments.tournaments_entry.class_group&fields=tournaments.tournaments_entry.start_date&fields=tournaments.tournaments_entry.end_date&fields=tournaments.tournaments_entry.course_country&fields=tournaments.tournaments_entry.ts_final_result&fields=tournaments.tournaments_entry.class_group&fields=tm_params.season_files&fields=tm_params.season_code&fields=seasons.seasons_entry&fields=tm_params.tour_name&fields=tm_params.hp_class&fields=tm_params.tour_logo&fields=code&fields=ts_final_result&randomadd=" +
    //           this.id +
    //           "/tmticx?randomadd=" +
    //           new Date().getTime()
    //       )
    //       .then((response) => (this.data = response.data))
    //   );
    // },
    changeYear: function(year) {
      console.log('Changing Year')
      return (
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              this.tour +
              "/" +
              year +
              "/tmticx?field=Y&fields=tournaments.tournaments_entry.code&fields=tournaments.tournaments_entry.short_name&fields=tournaments.tournaments_entry.days_away&fields=tournaments.tournaments_entry.full_name&fields=tournaments.tournaments_entry.sponsor_logo&fields=tournaments.tournaments_entry.dates&fields=tournaments.tournaments_entry.previous_winners&fields=tournaments.tournaments_entry.winner_name&fields=tournaments.tournaments_entry.winner_ref&fields=tournaments.tournaments_entry.course&fields=tournaments.tournaments_entry.total_prize_fund&fields=tournaments.tournaments_entry.class_group&fields=tournaments.tournaments_entry.start_date&fields=tournaments.tournaments_entry.end_date&fields=tournaments.tournaments_entry.course_country&fields=tournaments.tournaments_entry.ts_final_result&fields=tournaments.tournaments_entry.class_group&fields=tm_params.season_files&fields=tm_params.season_code&fields=seasons.seasons_entry&fields=tm_params.tour_name&fields=tm_params.hp_class&fields=tm_params.tour_logo&fields=code&fields=ts_final_result&randomadd=" +
              year +
              "/tmticx?randomadd=" +
              new Date().getTime()
          )
          .then(
            (response) =>
              (this.data = response.data)
          )
      );
    },
  },
  mounted() {
    // window.addEventListener("load", () => this.backBtn());
    const tour = sessionStorage.getItem("tour", "");
    if (tour === null) {
      this.tour = process.env.VUE_APP_URL_TOUR;
    } else this.tour = tour;
    const id = sessionStorage.getItem("id", "");
    if (id === null) {
      this.id = this.seasonCode;
    } else this.id = id;
    const pickedState = sessionStorage.getItem("pickedState", "");
    if (pickedState === null) {
      this.pickedState = "ALL";
    } else this.pickedState = pickedState;
    const pickedNum = sessionStorage.getItem("pickedNum", "");
    if (pickedNum === null) {
      this.pickedNum = "0";
    } else this.pickedNum = pickedNum;
    const pickedMonth = sessionStorage.getItem("pickedMonth", "");
    if (pickedMonth === null) {
      this.pickedMonth = "ALL";
    } else this.pickedMonth = pickedMonth;
    axios //add API Call
      .get(
        process.env.VUE_APP_TIC_BASE +
          this.tour +
          "/tmticx?field=Y&fields=tm_params.season_code&randomadd=1619597417671" +
          new Date().getTime()
      ) // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
        this.tm_params = response.data;
        this.id = response.data.tm_params.season_code;
        this.year = response.data.tm_params.season_code;
        this.seasonCode = response.data.tm_params.season_code;
        this.currentCode = response.data.code;
        return axios.get(
          process.env.VUE_APP_TIC_BASE +
            this.tour +
            "/" +
            this.id +
            "/tmticx?field=Y&fields=tournaments.tournaments_entry.code&fields=tournaments.tournaments_entry.short_name&fields=tournaments.tournaments_entry.days_away&fields=tournaments.tournaments_entry.full_name&fields=tournaments.tournaments_entry.sponsor_logo&fields=tournaments.tournaments_entry.dates&fields=tournaments.tournaments_entry.previous_winners&fields=tournaments.tournaments_entry.winner_name&fields=tournaments.tournaments_entry.winner_ref&fields=tournaments.tournaments_entry.course&fields=tournaments.tournaments_entry.total_prize_fund&fields=tournaments.tournaments_entry.class_group&fields=tournaments.tournaments_entry.start_date&fields=tournaments.tournaments_entry.end_date&fields=tournaments.tournaments_entry.course_country&fields=tournaments.tournaments_entry.ts_final_result&fields=tournaments.tournaments_entry.class_group&fields=tm_params.season_files&fields=tm_params.season_code&fields=seasons.seasons_entry&fields=tm_params.tour_name&fields=tm_params.hp_class&fields=tm_params.tour_logo&fields=code&fields=ts_final_result&randomadd=" +
            this.id+
            "/tmticx?randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.data = response.data;
        this.scheduleList = response.data.tournaments.tournaments_entry;
        let arr = [];
        this.scrollVaule = arr;
        console.log("arr")
        console.log(arr)
        this.scheduleList.forEach((value) => {
          if (value.code == sessionStorage.getItem("TournCode")) {
            console.log('InFOREACH')
            arr.push(value.code);
          }
        });
        localStorage.clear("Tournament Name");
        localStorage.clear("Tournament Link");
        // console.log("this.scheduleList")
        // console.log(this.scheduleList)
        // for(i in this.data) {
        //     this.tournCode  = i.code
        // }
      });
  },
};
</script>

<style scoped>
.execPad {
  padding-bottom: 20px;
}
.tRowTopMainnonMobile {
  background-color: transparent!important;
}
.tRowTopMain {
  background-color: rgba(0, 0, 0, 0.04);
}
.sections.mobSection {
  padding-left: 13px;
  padding-right: 12px;
  margin-top: -20px;
}
::v-deep .dropdown-item {
  color: #003C52!important;
}
.titleBackGround:before {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  background-color: #fff;
  z-index: 2;
  height: 100%;
  width: 25px;
}
.titleBackGround {
  text-align: left;
  width: auto;
  display: inline-block;
  background-color: #fff;
  z-index: 2;
  position: relative;
  padding-right: 25px;
  position: relative;
  padding-left: 2px;
}
/* .titleLine:before {
  content: "";
  display: block;
  height: 5px;
  width: 100%;
  background-color: #30e0bb;
  position: absolute;
  position: relative;
  top: 34px;
} */
.titleLineAbove {
  display: block;
  height: 5px;
  width: 100%;
  position: relative;
  top: 30px;
}
p.filterTitle {
  font-weight: 600;
  font-size: 17px;
  color: #003C52;
}
.shedBox {
  padding-bottom: 0px!important;
}
::v-deep td.tableTh.TableDefend {
  width: 250px;
}
::v-deep td.tableTh.tableTourn {
  width: 400px;
}
::v-deep td.tableTh.TableDates {
  width: 280px;
}
::v-deep td.tableTh.TableLogo {
  width: 120px;
}
div#text-field-container {
  position: relative;
  top: 10px;
  display: block !important;
  line-height: 42px;
}
.Findatourn {
  width: 100%;
  line-height: 33px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding-left: 10px;
}
input.Findatourn::placeholder {
  color: #aaa !important;
  padding-left: 10px;
  display: block;
  line-height: 34px;
  margin-top: 15px;
  position: relative;
  top: 0px;
  font-size: 11pt;
}
.groupDropdown {
  margin-top: 30px;
  margin-bottom: 30px;
}
.schedTour-btn {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
li {
  list-style-type: none;
}
::v-deep .nav-link {
  color: black !important;
  width: 100%;
}
.title {
  font-weight: 600;
  font-family: 'Gantari', sans-serif!important;
  color: #000;
  font-size: 50px;
  /* margin-top: 1em; */
  line-height: 1.1;
  /* letter-spacing: 2px; */
  padding-bottom: 30px;
}
.schImg {
  width: 100%;
  height: 60px;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.schImgmob {
  width: 100%;
  height: 70px;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.aus {
  background-image: url(https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/09/1.jpg);
}
.wpga {
  background-image: url(https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/09/2.jpg);
}
.snr {
  background-image: url(https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/09/4.jpg);
}
.all {
  background-image: url(https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/09/3.jpg);
}
.trn {
  background-image: url(https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/10/PGA-Schedule-boxes_PGA-Associates.png);
}
.ppc {
  background-image: url(https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/09/5.jpg);
}
.sections {
  padding: 20px 0;
}
.mobbox {
  z-index: 0;
  overflow: hidden;
  transform: translateZ(0);
  transition: box-shadow .3s ease-out, transform .3s ease-out, opacity .2s ease-out;
  border-top: 0px solid #fff;
  border-bottom: 0px solid #fff;
  background: #fff;
  margin-bottom: 17px;
  padding-bottom: 10px;
  margin-bottom: 10px !important;
  width: 100%;
}
.box {
  z-index: 0;
  overflow: hidden;
  -webkit-box-shadow: 0 3px 4px 0 rgb(35 35 35 / 10%), 0 0 1px 0 rgb(10 31 68 / 8%);
  box-shadow: 0 3px 4px 0 rgb(35 35 35 / 10%), 0 0 1px 0 rgb(10 31 68 / 8%);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: opacity .2s ease-out,-webkit-box-shadow .3s ease-out,-webkit-transform .3s ease-out;
  transition: opacity .2s ease-out,-webkit-box-shadow .3s ease-out,-webkit-transform .3s ease-out;
  transition: box-shadow .3s ease-out,transform .3s ease-out,opacity .2s ease-out;
  transition: box-shadow .3s ease-out,transform .3s ease-out,opacity .2s ease-out,-webkit-box-shadow .3s ease-out,-webkit-transform .3s ease-out;
  border-top: 0px solid #fff;
  border-bottom: 0px solid #fff;
  background: #fff;
  margin-bottom: 17px;
  padding-bottom: 10px;
  margin-bottom: 20px!important;
  width: 100%;
}

.box:hover {
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgb(38 38 38 / 20%);
  top: -4px;
  background-color: white;
  box-shadow: 0 2px 2px rgb(45 45 45 / 5%), 0 4px 4px rgb(49 49 49 / 5%),
    0 8px 8px rgb(42 42 42 / 5%), 0 16px 16px rgb(32 32 32 / 5%),
    0 32px 32px rgb(49 49 49 / 5%), 0 64px 64px rgb(35 35 35 / 5%);
  -webkit-transform: translate3D(0, -4px, 0);
  transform: translate3D(0, -2px, 0);
}
.ad {
  padding: 15px 0;
}
.card-remove-border {
  border: none;
}
nav.card-header.dropdown-seasons {
  padding-left: 30px;
  background: none;
  border: none;
  border-top: 0px;
}
::v-deep button {
  color: black;
  background-color: white;
  border: 0px solid black;
  border-radius: 0;
  width: 289px;
  text-align: left;
  /* font-weight: bold; */
}
::v-deep button::after {
  float: right;
  margin-top: 10px;
}
::v-deep button:hover {
  color: #003C52;
  background-color: white;
}
::v-deep th {
  color: white;
  background-color: #003C52;
}
::v-deep tr:nth-child(even) {
  /* background-color: #dfe1e6; */
}
.tRow {
  border-bottom: 0px solid #000;
  padding: 2px 0;
  background: rgba(0, 0, 0, 0.04);
}
::v-deep ul.nav.dropdownSched > div > button {
  line-height: 26px;
  height: 38px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.titleMainTour {
  color: #000;
  margin: 0;
  font-size: 10pt;
  font-weight: 700;
  line-height: 2;
  position: relative;
  top: 3px;
}
li {
  list-style-type: none;
}
.menuLink {
  text-align: right;
}
.menuLink > a {
  padding: 4px 0 0 3em;
  text-transform: capitalize;
  font-size: 10pt;
  font-weight: 500;
}
.tourLogo {
  max-width: 80px !important;
  margin-top: -3px;
  padding-left: 0;
  margin-left: 15px;
  max-height: 34px !important;
  width: initial !important;
}
@media only screen and (min-width: 1201px) {
  .sections.mobSection.showMobTours {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  .sections.showDeskTours {
    display: none;
  }
  .schImgmob {
    width: 100%;
    height: 35px;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media only screen and (min-width: 1024px) {
  .showMob {
    display: none;
  }
}
@media only screen and (max-width: 1023px) {
  .showDesk {
    display: none;
  }
.groupDropdown.ShowIpadGroup {
    display: none;
}
}
@media only screen and (max-width: 1024px) {
  ::v-deep .schImg {
    width: 100%;
    height: 40px!important;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .groupDropdown.ShowDeskGroup {
    display: none;
  }
}
@media only screen and (min-width: 991px) {
  ::v-deep .img.img_ad {
    width: 728px!important;
    height: 90px!important;
  }
  .groupDropdown.ShowIpadGroup {
    display: none;
  }
  .showMobGroup {
    display: none;
  }
}
@media only screen and (max-width: 990px) and (min-width: 769px){
  .schImgmob {
    width: 100%;
    height: 29px;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .schedTour-btn {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media only screen and (max-width: 990px) {
  .showDeskGroup {
    display: none;
  }
  .showMobGroup {
    display: none;
  }
  .groupDropdown.ShowIpadGroup {
      display: block;
  }
  p.filterTitle {
    font-weight: 600;
    font-size: 17px;
    color: #003C52;
    margin-bottom: 6.5px;
  }
}

@media only screen and (max-width: 769px) {
  .groupDropdown.ShowIpadGroup {
      display: none;
  }
  ::v-deep .img.img_ad {
    width: 320px!important;
    height: 50px!important;
  }
  .schImgmob {
    width: 100%;
    height: 70px;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .name {
    margin: auto;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    text-align: center;
    margin-bottom: 0px;
    text-align: center;
    line-height: 1.3;
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .card {
    height: 320px;
  }
  .showDeskGroup {
    display: none!important;
  }
  .showMobGroup {
    display: block!important;
  }
  .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    /* background: #fff url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e) right 0.75rem center / 8px 10px no-repeat;  */
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
  }
  input.Findatourn::placeholder {
    color: #000000 !important;
    padding-left: 10px;
    display: block;
    line-height: 34px;
    margin-top: 15px;
    position: relative;
    top: 0px;
    font-size: 16px;
  }
  .groupDropdown {
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .sections {
    padding: 10px 0;
  }
  p.filterTitle {
    font-weight: 600;
    font-size: 17px;
    color: #003C52;
    margin-top: 10px;
  }
  .titleLine:before {
    content: "";
    display: block;
    height: 5px;
    width: 100%;
    /* background-color: #30e0bb; */
    position: absolute;
    position: relative;
    top: 25px;
  }
  .title {
    font-weight: 600;
    font-family: 'Gantari', sans-serif!important;
    color: #000;
    font-size: 33px;
    /* margin-top: 1em; */
    line-height: 1.1;
    /* letter-spacing: 2px; */
    padding-bottom: 30px;
  }
  ::v-deep ul.nav.dropdownSched {
    width: 100%;
    max-width: 100%;
    flex: 100%;
    display: inline-grid;
  }
  ::v-deep .Findatourn {
    width: -webkit-fill-available;
    line-height: 33px;
    background-color: white;
    /* border: 1px solid black; */
    padding-left: 10px;
    font-size: 16px;
  }
  .mobHide {
    display: none;
  }
  .mobButton {
    padding: 5px 0;
  }
  ::v-deep .schImg {
    /* height: 160px; */
    /* height: 115px; */
    /* height: 70px; */
    height: 64px!important;
  }
  ::v-deep .mobButton {
    padding: 10px;
    width: 33.333%;
    max-width: 33.333%;
    flex: 33.333%;
    padding-bottom: 0;
  }
  ::v-deep .TableDefend {
    display: none;
  }
}
@media only screen and (max-width: 768px) and (min-width: 501px){
  .schImgmob {
    width: 100%;
    height: 51px;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
@media only screen and (max-width: 500px) {
  .schImgmob {
    width: 100%;
    height: 35px;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
</style>
